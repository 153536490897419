<template>
  <BaseDetail
    :is-loading="loading"
  >
    <template #header>
      <BaseHeader
        :save-btn="$can(null, 'editSubscription')"
        :delete-btn="false"
        @save="handleSave"
        @close="handleClose"
        :deleteDataBaseBtn="$can(null, 'editSubscription') && $route.params.id !== 'create'"
        @dataBaseDelete="isDataBaseDelete = true"
      />
    </template>
    <template #content>
      <DetailView
        v-if="detail"
        v-model="detail"
        ref="content"
      />
      <BaseDeleteConfirm
        v-if="detail"
        :id="Number(detail.id)"
        :isDialog="isDataBaseDelete"
        @closeDialog="isDataBaseDelete = false"
        @delete="handleDelete(1)"
      />
    </template>
  </BaseDetail>
</template>

<script>
import BaseDetail from '@/components/base/BaseDetail'
import BaseDeleteConfirm from '@/components/base/BaseDeleteConfirm'
import DetailView from './detail/DetailView'
import detail from '@/components/mixins/detail'
import { required } from 'vuelidate/lib/validators'
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'

export default {
  mixins: [detail],
  name: 'Detail',
  components: {BaseDetail, DetailView, BaseHeader, BaseDeleteConfirm},
  props: {
    value: Object
  },
  data: () => {
    return {
      isDataBaseDelete: false
    }
  },
  validations: {
    detail: {
      from_date: {
        required
      },
      to_date: {
        required
      },
      organization: {
        id: {
          required
        }
      },
      subscriptionPassports: {
        required
      }
    }
  },
  computed: {
    modelIdName () {
      let id = 'id'
      if (this.$route.name === 'organizationSubscriptionDetail') {
        id = 'subscriptionId'
      }
      return id
    },
    controllerName () {
      return 'subscription'
    },
    modelData () {
      let data = null
      if (this.$route.name === 'organizationSubscriptionDetail' && this.$route.params.subscriptionId === 'create') {
        data = {
          organization: {
            address: this.value.address,
            city: this.value.city,
            id: this.value.id,
            name: this.value.name,
            region: this.value.region,
            role: this.value.role
          }
        }
      }
      return data
    }
  }
}
</script>

<style scoped>

</style>
