<template>
  <div>
    <v-list
      subheader
      two-line
      outlined
      v-if="value.length"
    >
      <div
        v-for="(passport, index) in value"
        :key="index +'-'+ passport.length"
      >
        <SubscriptionPassportItem :value="passport"/>
      </div>
    </v-list>
    <BaseAlert
      v-else
      type="error"
      :text="$t('message.noSubscriptionPassport')"
    />
    <v-messages
      color="error"
      :value="errorMessages"
    ></v-messages>
  </div>
</template>

<script>
import SubscriptionPassportItem
  from '@/components/views/account/subscription/detail/subscriptionPassportList/SubscriptionPassportItem'
import BaseAlert from '@/components/base/UI/BaseAlert'

export default {
  name: 'SubscriptionPassportList',
  components: { BaseAlert, SubscriptionPassportItem },
  props: {
    value: Array,
    errorMessages: Array
  }
}
</script>

<style scoped>

</style>
