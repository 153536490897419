import { render, staticRenderFns } from "./PassportList.vue?vue&type=template&id=440f6c90&scoped=true&"
import script from "./PassportList.vue?vue&type=script&lang=js&"
export * from "./PassportList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "440f6c90",
  null
  
)

export default component.exports