<template>
  <v-card
    flat
  >
    <v-card-text>
      <v-row>
        <v-col
          cols="10"
          md="10"
        >
          <v-card
            class="mb-1"
            outlined
            tile
          >
            <v-card-subtitle>{{ $t('subscription.rate') }}</v-card-subtitle>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <div style="width: 450px">
                  <BaseDatePicker
                    v-model="localValue.from_date"
                    :disabled="!$can(null, 'editSubscription')"
                    :label="$t('subscription.fromDate')"
                    :error-messages="checkErrors('detail.from_date')"
                  />
                </div>
                <div
                  class="ml-1"
                  style="width: 450px"
                >
                  <BaseDatePicker
                    v-model="localValue.to_date"
                    :disabled="!$can(null, 'editSubscription')"
                    :label="$t('subscription.toDate')"
                    :error-messages="checkErrors('detail.to_date')"
                  />
                </div>
              </div>
              <v-text-field
                v-model="localValue.price"
                :disabled="!$can(null, 'editSubscription')"
                color="secondary"
                :label="$t('subscription.price')"
                dense
                type="number"
                outlined
                prepend-icon="mdi-currency-rub"
              ></v-text-field>
            </v-card-text>
          </v-card>
          <v-card
            v-if="$route.name !== 'organizationSubscriptionDetail'"
            class="mt-1 mb-1"
            outlined
            tile
          >
            <v-card-subtitle>{{ $t('organization.name') }}</v-card-subtitle>
            <v-card-text>
              <BaseAutocomplete
                :prepend-inner-icon="'mdi-magnify'"
                v-model="localValue.organization"
                item-value="id"
                item-text="name"
                :disabled="!$can(null, 'editSubscription')"
                :label="$t('organization.name')"
                url="/organization/autocomplete"
                :chars-to-search="3"
                clearable
                outlined
                dense
                hide-details
                color="secondary"
                :error-messages="checkErrors('detail.organization.id')"
              />
            </v-card-text>
          </v-card>
          <v-card
            class="mt-1"
            outlined
            tile
          >
            <!--todo: стилизовать-->
            <div class="d-flex justify-lg-space-between">
              <v-card-title bold>{{ $t('subscription.passportList') }}</v-card-title>
              <v-card-text
                class="pt-0 pb-0 demo"
                v-if="isDemo"
              >
                Демо подписка
              </v-card-text>
              <BaseBtn
                class="ma-3"
                small
                width="115px"
                color="primary"
                @click="dialog = !dialog"
                :disabled="!$can(null, 'editSubscription') || isDemo || isLoading"
                :title="$t('base.choose')"
                :loading="isLoading"
              />
            </div>
            <PassportList
              v-model="localValue.subscriptionPassports"
              :dialog.sync="dialog"
              :name="value.organization.name"
              @loading="isLoading = $event"
            />
            <v-card-text>
              <SubscriptionPassportList
                v-model="localValue.subscriptionPassports"
                :error-messages="checkErrors('detail.subscriptionPassports')"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import BaseDatePicker from '@/components/base/BaseDatePicker'
import SubscriptionPassportList from '@/components/views/account/subscription/detail/SubscriptionPassportList'
import PassportList from '@/components/views/account/subscription/detail/PassportList'
import validationErrors from '@/components/mixins/validationErrors'
import BaseAutocomplete from '@/components/base/BaseAutocomplete'
import BaseTable from '@/components/base/BaseTable'
import BaseRelatedTable from '@/components/base/BaseRelatedTable'
import BaseBtn from '@/components/base/UI/BaseBtn'

export default {
  mixins: [validationErrors],
  name: 'DetailView',
  components: {
    BaseRelatedTable,
    BaseAutocomplete,
    PassportList,
    SubscriptionPassportList,
    BaseDatePicker,
    BaseTable,
    BaseBtn
  },
  props: {
    value: Object
  },
  inject: ['$v'],
  data () {
    return {
      dialog: false,
      localValue: {},
      isLoading: false
    }
  },
  computed: {
    isDemo () {
      const value = this.$store.getters['serviceData/get']('subscription.accessType')?.find(item => item.value === this.localValue.access_type)
      return value ? value.label === 'Демо' : false
    }
  },
  watch: {
    value: {
      handler () {
        this.localValue = this.value
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>
  .demo {
    position: absolute;
    top: 0;
    text-align: end;
  }
</style>
