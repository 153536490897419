<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>{{value.passport.properties.object_name}}</v-list-item-title>
      <v-list-item-subtitle>{{value.passport.properties.territorial_affiliation}}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'SubscriptionPassportItem',
  props: {
    value: Object
  }
}
</script>

<style scoped>

</style>
