<template>
  <BaseRelatedTable
    :value="dialog"
    @input="$emit('update:dialog', $event)"
    :title="$t('subscription.choosePassportList')"
  >
    <template #table>
      <BaseTable
        v-model="selectedItems"
        :headers="localHeaders"
        :items="items"
        :options.sync="localQuery.options"
        :loading="isLoading"
        :show-select="true"
        :all-items="allItems.models"
        item-key="properties.id"
      >
        <template #filter>
          <BaseFilter
            :is-empty-filter="isEmptyFilter"
            @handleSearch="handleSearch"
            @clearFilter="handleClear"
            :value="isDialogFilter"
          >
            <template #filters>
              <FilterPassport
                @change="changeField"
                @emptyField="emptyField"
                is-flexed
              />
            </template>
          </BaseFilter>
        </template>
        <template #item="{item, isSelected}">
          <ListItem
            :is-selected="isSelected"
            selectable
            :item="item"
            :can-click="false"
            @input="handleAndEmitSelected($event)"
          />
        </template>
      </BaseTable>
    </template>
  </BaseRelatedTable>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import ListItem from '@/components/views/account/passport/list/ListItem'
import headers from '@/components/views/account/passport/list/headers'
import BaseRelatedTable from '@/components/base/BaseRelatedTable'
import tableSelectedItems from '@/components/mixins/tableSelectedItems'
import table from '@/components/mixins/table'
import BaseRegionSelect from '@/components/base/forms/BaseRegionSelect'
import BaseFilter from '@/components/base/BaseFilter'
import FilterPassport from '@/components/views/account/passport/common/FilterPassport'
import filter from '@/components/mixins/filter'

export default {
  mixins: [tableSelectedItems, table, filter],
  name: 'PassportList',
  components: { BaseRegionSelect, BaseRelatedTable, BaseTable, ListItem, BaseFilter, FilterPassport },
  props: {
    dialog: Boolean,
    name: String
  },
  data () {
    return {
      localHeaders: headers,
      allItems: {
        models: [],
        count: 0
      }
    }
  },
  computed: {
    controllerName () {
      return 'passport'
    },
    region: {
      get () {
        return this.localQuery.filter.regionId
      },
      set (val) {
        this.$set(this.localQuery.filter, 'regionId', val)
      }
    }
  },
  methods: {
    formatSelected (passport) {
      return { id: null, passport }
    },
    transformValue (valueItem) {
      return valueItem.passport
    },
    findValueItem: item => data => item.properties.id === data.passport.properties.id,
    handleClear () {
      for (let key in this.changesFields) {
        this.$delete(this.localQuery.filter, key)
      }
      this.$set(this, 'changesFields', {})
      this.clearSelectedItems()
      this.isDialogFilter = !this.isDialogFilter
    },
    handleSearch () {
      this.$set(this.localQuery, 'filter', { ...this.filter, ...this.changesFields })
      this.isDialogFilter = !this.isDialogFilter
    },
    async fillAllItems () {
      if (!this.isLoading) {
        this.$emit('loading', true)
        this.isLoading = true
        this.allItems = await this.$store.dispatch('server/get', {
          url: '/' + this.controllerName,
          params: {
            options: {}
          }
        }) || { models: [], count: 0 }
        this.isLoading = false
        this.$emit('loading', false)
      }
      return true
    }
  },
  mounted () {
    this.fillAllItems()
    this.$set(this.localQuery, 'filter', {})
  }
}
</script>

<style scoped>

</style>
